import React, { Component } from 'react';
import './App.css';
import { SeatsioSeatingChart } from '@seatsio/seatsio-react';
import Sticky from 'react-sticky-el';

class App extends Component {
  render() {
    return (
      <div className="container">
                <div className="scrollarea" style = {{position: 'relative'}}>
                    <div className="col-md-5 float-right">
                        <SeatsioSeatingChart
                            publicKey="publicDemoKey"
                            event="smallTheatreEvent"
                        /> 
                        <p>text</p><p>text</p><p>text</p><p>text</p><p>text</p><p>text</p><p>text</p>
                        <p>text</p><p>text</p><p>text</p><p>text</p><p>text</p><p>text</p><p>text</p>
                        <p>text</p><p>text</p><p>text</p><p>text</p><p>text</p><p>text</p><p>text</p>
                        <p>text</p><p>text</p><p>text</p><p>text</p><p>text</p><p>text</p><p>text</p>
                        <p>text</p><p>text</p><p>text</p><p>text</p><p>text</p><p>text</p><p>text</p>
                        <p>text</p><p>text</p><p>text</p><p>text</p><p>text</p><p>text</p><p>text</p>
                        <p>text</p><p>text</p><p>text</p><p>text</p><p>text</p><p>text</p><p>text</p>
                        <p>text</p><p>text</p><p>text</p><p>text</p><p>text</p><p>text</p><p>text</p>
                        <p>text</p><p>text</p><p>text</p><p>text</p><p>text</p><p>text</p><p>text</p>
                        <p>text</p><p>text</p><p>text</p><p>text</p><p>text</p><p>text</p><p>text</p>
                        
                    </div>
                    <Sticky boundaryElement=".scrollarea" style = {{position: 'relative'}} hideOnBoundaryHit={false} topOffset={100} top={100}>
                        <div className="col-md-4 float-left" >
                            <p>sticky</p><p>sticky</p><p>sticky</p><p>sticky</p><p>sticky</p><p>sticky</p>
                            <p>sticky</p><p>sticky</p><p>sticky</p><p>sticky</p><p>sticky</p><p>sticky</p>
                            <p>sticky</p><p>sticky</p><p>sticky</p><p>sticky</p><p>sticky</p><p>sticky</p>
                            <p>sticky</p><p>sticky</p><p>sticky</p><p>sticky</p><p>sticky</p><p>sticky</p>
                        </div>   
                    </Sticky>
                    <div className="clearfix"></div>
                </div>
                <div>
                    <p>bottom</p>
                    <p>bottom</p>
                    <p>bottom</p>
                    <p>bottom</p>
                    <p>bottom</p>
                    <p>bottom</p>
                </div>
            </div>
    );
  }
}

export default App;
